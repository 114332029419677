import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

export default function RegisterServerDialog(props) {
  const [name, setName] = React.useState('');

  function handleChange(event) {
    setName(event.target.value);
  }

  function addServer() {
    props.onSubmit(name);
  }

  return (
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Register New Server</DialogTitle>
        <DialogContent>
          <TextField value={name} onChange={handleChange} variant="outlined" label="Server Name" helperText="Server name to identify the server in your server list. This is not public." />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">Cancel</Button>
          <Button onClick={addServer} color="primary">Add Server</Button>
        </DialogActions>
      </Dialog>
  );
}
