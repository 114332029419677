import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as FeudalToolsIcon } from './common/feudaltools.svg';
import { ReactComponent as LifxIcon } from './common/lifx.svg';

import LandingPageSection from './LandingPageSection'
import LandingPageTop from './LandingPageTop';
import LandingPageFeatures from './LandingPageFeatures';
import LandingPageCommunity from './LandingPageCommunity';


export default function LandingPage(props) {
  return (
    <>
      <LandingPageSection>
        <LandingPageTop />
      </LandingPageSection>
      <LandingPageSection>
        <LandingPageFeatures {...props} />
      </LandingPageSection>
      <LandingPageSection>
        <LandingPageCommunity />
      </LandingPageSection>
      <LandingPageSection footer>
        <BottomNavigation>
          <BottomNavigationAction label="LiFx" icon={<SvgIcon component={LifxIcon} viewBox="0 0 244 216" fontSize="large" />} href="https://lifxmod.com" target="_blank" />
          <BottomNavigationAction label="FeudalTools" icon={<SvgIcon component={FeudalToolsIcon} viewBox="0 0 158 140" fontSize="large" />} href="https://feudal.tools" target="_blank" />
        </BottomNavigation>
      </LandingPageSection>
    </>
  );
}
