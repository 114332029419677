import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import DownloadIcon from '@material-ui/icons/GetApp';
import ChatIcon from '@material-ui/icons/Chat';

import { ReactComponent as LifIcon } from './common/lifeisfeudal.svg';

export default function LandingPageTop(props) {
  return (
    <Grid container justify="center" spacing={3}>
      <Grid item md={6}>
        <Paper elevation={0} style={{padding: "2em"}}>
          <Typography gutterBottom variant="h4">Serverlist, Mod-Manager & Launcher for <SvgIcon component={LifIcon} viewBox="0 0 64 64" color="disabled" /> Life is Feudal: Your Own</Typography>
          <Typography gutterBottom variant="subtitle1">Wrapped in a modern, self-updating desktop app.<br />A joint project between LiF-x and FeudalTools.</Typography>
          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item>
                <Button href="https://files.yolauncher.app/YoLauncherSetup.exe" color="primary" variant="contained" startIcon={<DownloadIcon />}>Download App</Button>
              </Grid>
              <Grid item>
                <Button href="#community" variant="contained" startIcon={<ChatIcon />}>Get Involved</Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6}>
        <img src="YoLauncherWindows.png" />
      </Grid>
    </Grid>
  );
}
