import React from 'react';
import Box from '@material-ui/core/Box';
import AddCircle from '@material-ui/icons/AddCircle';
import CheckCircle from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import LabelIcon from '@material-ui/icons/Label';
import HelpIcon from '@material-ui/icons/Help';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

import { ReactComponent as PackageIcon } from './common/package.svg';
import MuiMaterialTable from './MuiMaterialTable';
import EditServerDialog from './EditServerDialog';
import RegisterServerDialog from './RegisterServerDialog';
import DownloadConnectorDialog from './DownloadConnectorDialog';
import VerificationProgressDialog from './VerificationProgressDialog';
import ClientModsDialog from './ClientModsDialog';
import ModpackDialog from './ModpackDialog';
import TagsDialog from './TagsDialog';
import UploadProgressDialog from './UploadProgressDialog';
import useAPI from './useAPI';

export default function ServerManager() {
  const [busy, setBusy] = React.useState(true);
  const [servers, setServers] = React.useState();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadProgressOpen, setUploadProgressOpen] = React.useState(false);
  const [registerDialogOpen, setRegisterDialogOpen] = React.useState(false);
  const { apiRequest, upload } = useAPI();

  const columns = [
    { title: 'Server', field: 'name', type: 'string' },
    { title: 'Address', sorting: false, field: 'address', type: 'string', render: data => <AddressColumn data={data} /> },
    { title: 'Owner Verification', sorting: false, field: 'verified', type: 'string', render: data => <VerificationColumn vTime={data.validation_time} vAge={data.validation_age} /> },
    { title: 'Modpack', sorting: false, type: 'string', render: data => <ModpackColumn data={data} onUploadModpack={file => updateServerModpack(data.ID, file)} onSelectModpack={packId => selectServerModpack(data.ID, packId)} /> },
    { title: 'Options', sorting: false, render: data => <OptionsColumn data={data} onDelete={() => deleteServer(data.ID)} onUpdate={updateServer} onUpdateTags={tagIDs => updateTags(data.ID, tagIDs)} onUpdateClientMods={mods => updateClientMods(data.ID, mods)} onUpdateModpack={file => updateServerModpack(data.ID, file)} /> },
  ];

  const options = { toolbar: false, search: false, showTitle: false, paging: false };

  function getServers() {
    apiRequest(`/v1/server.php?method=list`).then( res => {
      setServers(res.payload);
      setBusy(false);
    } );
  }

  function registerServer(name) {
    setBusy(true);
    setRegisterDialogOpen(false);
    apiRequest(`/v1/server.php?method=register&name=${name}`).then(getServers);
  }

  function updateServer(data) {
    setBusy(true);
    const params = Object.entries(data).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
    apiRequest(`/v1/server.php?method=update&${params}`).then(getServers);
  }

  function updateTags(id, tagIDs) {
    setBusy(true);
    const params = tagIDs.map(tagID => `tags[]=${tagID}`).join('&');
    apiRequest(`/v1/server.php?method=update_tags&id=${id}&${params}`).then(getServers);
  }

  function updateClientMods(id, mods) {
    setBusy(true);
    const params = mods.map(mod => `mod_id[]=${mod.id}&rule[]=${mod.rule}`).join('&');
    apiRequest(`/v1/server.php?method=update_clientmods&id=${id}&${params}`).then(getServers);
  }

  function updateServerModpack(id, file) {
    setBusy(true);
    setUploadProgress(0);
    setUploadProgressOpen(true);
    const params = {method: 'upload_modpack', id: id};
    upload(`/v1/server.php`, params, file, progressEvent => {
      const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
      totalLength !== null && setUploadProgress(progressEvent.loaded / totalLength * 100);
    }).then(() => {
      setUploadProgressOpen(false);
      getServers();
    });
  }

  function selectServerModpack(id, packId) {
    setBusy(true);
    apiRequest(`/v1/server.php?method=select_modpack&id=${id}&pack_id=${packId}`).then(getServers);
  }

  function deleteServer(id) {
    setBusy(true);
    apiRequest(`/v1/server.php?method=delete&id=${id}`).then(getServers);
  }

  React.useEffect(getServers, []);

  return (
    <>
      <Box mb={2}>
        <Button color="primary" variant="contained" onClick={() => setRegisterDialogOpen(true)} startIcon={<AddCircle />}>Add Server</Button>
        <RegisterServerDialog open={registerDialogOpen} onClose={() => setRegisterDialogOpen(false)} onSubmit={registerServer} />
      </Box>
      <MuiMaterialTable isLoading={busy} data={servers} columns={columns} options={options} />
      <UploadProgressDialog open={uploadProgressOpen} progress={uploadProgress} />
    </>
  );
}

function AddressColumn({data}) {
  return data.validation_time !== null ? `${data.ip}:${data.port}` : '---';
}

function VerificationColumn(props) {
  const [helpOpen, setHelpOpen] = React.useState(false);
  return (
    <>
      <VerificationChip {...props} />
      <IconButton onClick={() => setHelpOpen(true)}>
        <HelpIcon />
      </IconButton>
      <VerificationProgressDialog open={helpOpen} onClose={() => setHelpOpen(false)} verified={props.vTime !== null} />
    </>
  )
}

function VerificationChip({vTime, vAge}) {
  if( vTime === null ) return <Chip label="Pending" />;
  if( vAge > 3600*24*7 ) return <Chip label="Expired" />;
  return <Chip color="primary" avatar={<CheckCircle />} label="Confirmed" />
}

function ModpackColumn({data, onUploadModpack, onSelectModpack}) {
  const [modpackDialogOpen, setModpackDialogOpen] = React.useState(false);
  const [selectMenuAnchor, setSelectMenuAnchor] = React.useState(null);
  const packCount = data.modpacks.length;
  const activePack = data.modpacks.find(pack => parseInt(pack.active) === 1);
  const selectActivePack = (id) => {
    onSelectModpack(id);
    setSelectMenuAnchor(null);
  }
  return data.validation_time === null ? <Chip label="none" /> : (
    <>
      <Chip label={activePack ? "Distributing Version " + activePack.version : `none, ${packCount} stored` } />
      <Tooltip title="Select Active Modpack">
        <IconButton onClick={(event) => setSelectMenuAnchor(event.currentTarget)}>
          <EditIcon color={packCount === 0 ? 'disabled' : 'inherit'} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Upload New Modpack">
        <IconButton onClick={() => setModpackDialogOpen(true)} >
          <SvgIcon component={PackageIcon} viewBox="0 0 512 512" />
        </IconButton>
      </Tooltip>
      <Menu keepMounted anchorEl={selectMenuAnchor} open={Boolean(selectMenuAnchor)} onClose={() => setSelectMenuAnchor(null)} TransitionComponent={Fade}>
        <MenuItem onClick={() => selectActivePack(0)}>None (disable)</MenuItem>
        { data.modpacks.map( pack => <MenuItem key={pack.ID} onClick={() => selectActivePack(pack.ID)}>Version {pack.version} ({Math.round((pack.filesize/1024/1024)*10)/10} MB)</MenuItem> ) }
      </Menu>
      <ModpackDialog open={modpackDialogOpen} onClose={() => setModpackDialogOpen(false)} onSubmit={onUploadModpack} />
    </>
  );
}

function OptionsColumn({data, onDelete, onUpdate, onUpdateTags, onUpdateClientMods, onUpdateModpack}) {
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [tagsDialogOpen, setTagsDialogOpen] = React.useState(false);
  const [clientModsDialogOpen, setClientModsDialogOpen] = React.useState(false);
  const { download } = useAPI();
  return (
    <>
      <Tooltip title="Download Connector Mod">
        <IconButton onClick={() => setDownloadDialogOpen(true)}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Customize">
        <IconButton onClick={() => data.validation_time === null || setEditDialogOpen(true)} >
          <SettingsIcon color={data.validation_time === null ? 'disabled' : 'inherit'} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Tag Manager">
        <IconButton onClick={() => data.validation_time === null || setTagsDialogOpen(true)} >
          <LabelIcon color={data.validation_time === null ? 'disabled' : 'inherit'} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Client Mod Settings">
        <IconButton onClick={() => data.validation_time === null || setClientModsDialogOpen(true)} >
          <PlaylistAddCheckIcon color={data.validation_time === null ? 'disabled' : 'inherit'} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Server">
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <DownloadConnectorDialog open={downloadDialogOpen} onClose={() => setDownloadDialogOpen(false)} onDownload={() => download(`/v1/server.php?method=download_mod&id=${data.ID}`)} />
      <EditServerDialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} onSubmit={onUpdate} data={data} />
      <ClientModsDialog open={clientModsDialogOpen} clientMods={data.clientmods} onSubmit={onUpdateClientMods} onClose={() => setClientModsDialogOpen(false)} />
      <TagsDialog open={tagsDialogOpen} tags={data.tags.map(tag => tag.ID)} onClose={() => setTagsDialogOpen(false)} onSubmit={onUpdateTags} />
    </>
  );
}
