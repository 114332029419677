import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function LandingPageFeatures(props) {
  return (
    <>
      <Typography gutterBottom variant="h3" align="center">Community</Typography>
      <Paper elevation={0} style={{padding: "2em", fontSize: "1.125em"}}>
        <p id="community">We are a community of mod creators and players who want to share both our love of LiF and the creations we make for it. The LiFx Community is a place where modders can work together to build within a single framework ensuring mod compatibility with a simple setup. We are always looking for more excited and eager individuals who are inspired to create, share, and play in ways that improve both their own experience and the LiF Community as a whole.</p>
        <div style={{textAlign:"center"}}>
          <strong><Link href="https://discord.gg/EH9b6tqQ4C">Join us on Discord</Link></strong><br />
          <a href="https://discord.gg/EH9b6tqQ4C"><img src="https://discord.com/api/guilds/779866175134892082/widget.png?style=shield" /></a>
        </div>
      </Paper>
    </>
  );
}
