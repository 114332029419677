import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function LoginDialog(props) {
  return (
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Steam Login</DialogTitle>
        <DialogContent>
          <DialogContentText>Authenticate with your Steam account to manage your own servers</DialogContentText>
          <div>
            <a href={`${process.env.REACT_APP_API_URL}/v1/user.php?method=login`}>
              <img src="https://community.akamai.steamstatic.com/public/images/signinthroughsteam/sits_02.png" />
            </a>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
  );
}
