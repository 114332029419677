import React from 'react';
import ReactDOM from 'react-dom';
import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import App from './App';
import './index.css';

const muiTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: { light: "#4fc3f7", main: "#29b6f6", dark: "#03a9f4" },
    secondary: { light: "#ffeb3b", main: "#fbc02d", dark: "#f57f17" }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
