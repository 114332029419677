import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import AddCircle from '@material-ui/icons/AddCircle';
import useAPI from './useAPI';

export default function TagsDialog(props) {
  const [tags, setTags] = React.useState([]);
  const [tagIDs, setTagIDs] = React.useState(props.tags);
  const { apiRequest } = useAPI();

  function getTags() {
    if( tags.length ) return;
    apiRequest(`/v1/server.php?method=list_tags`).then( res => {
      setTags(res.payload);
    } );
  }

  function saveChanges() {
    props.onClose();
    props.onSubmit(tagIDs);
  }

  function addTag(id) {
    setTagIDs(prev => [...prev, id]);
  }

  function removeTag(id) {
    setTagIDs(prev => prev.filter(tagid => tagid !== id));
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} onEntering={getTags}>
      <DialogTitle>Manage Server Tags</DialogTitle>
      <DialogContent>
        <Box>
          <em>Server Tags:</em>
        </Box>
        <Box mb={3} mt={1}>
          { tags.filter(tag => tagIDs.includes(tag.ID)).map( tag => (
            <Tooltip key={tag.ID} title={tag.description}>
              <Chip label={tag.tag} style={{marginRight:5, backgroundColor:`#${tag.color}`}} onDelete={() => removeTag(tag.ID)} />
            </Tooltip>
          ) ) }
          { !Boolean(tagIDs.length) && <em>none</em> }
        </Box>
        <Box>
          <em>Available Tags:</em>
        </Box>
        <Box mb={3} mt={1}>
          { tags.filter(tag => !tagIDs.includes(tag.ID)).map( tag => (
            <Tooltip key={tag.ID} title={tag.description}>
              <Chip clickable label={tag.tag} icon={<AddCircle />} style={{marginRight:5, backgroundColor:`#${tag.color}`}} onClick={() => addTag(tag.ID)} />
            </Tooltip>
          ) ) }
          { !Boolean(tags.filter(tag => !tagIDs.includes(tag.ID)).length) && <em>none</em> }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">Cancel</Button>
        <Button onClick={saveChanges} color="primary">Save Changes</Button>
      </DialogActions>
    </Dialog>
  );
}
