import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Box from '@material-ui/core/Box';

export default function ClientModsDialog(props) {

  const [mods, setMods] = React.useState([]);

  function setState() {
    setMods(props.clientMods.map(mod => ({...mod, rule: mod.rule === null ? 0 : parseInt(mod.rule)})));
    console.log(props.clientMods);
  }

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    setMods( prev => prev.map( mod => {
      if( mod.friendlyName === name ) mod.rule = value;
      return mod;
    } ) );
  }

  function saveChanges() {
    props.onSubmit(mods);
    props.onClose();
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} onEntering={setState} >
      <DialogTitle>Client Mod Settings</DialogTitle>
      <DialogContent>
        { mods.sort().map( mod => (
          <FormGroup row key={mod.id}>
            <Box component="span" m={2} width={1/2}>{mod.displayName}</Box>
            <FormControl variant="outlined" margin="dense" style={{minWidth: 180}}>
              <InputLabel id={`rule-${mod.id}`}>YoLauncher Rule</InputLabel>
              <Select label="YoLauncher Rule" labelId={`rule-${mod.id}`} name={mod.friendlyName} value={mod.rule} onChange={handleChange}>
                <MenuItem value={0}><em>None</em></MenuItem>
                <MenuItem value={1}>Recommended</MenuItem>
                <MenuItem value={2}>Required</MenuItem>
                <MenuItem value={3}>Forbidden</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        ) ) }
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">Cancel</Button>
        <Button onClick={saveChanges} color="primary">Save Changes</Button>
      </DialogActions>
    </Dialog>
  );
}
