import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import DownloadIcon from '@material-ui/icons/GetApp';

export default function DownloadConnectorDialog(props) {
  return (
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Download Connector Mod</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The mod is generated and customized for your server. Do not share your downloaded version with anyone!
          </DialogContentText>
          <DialogContentText>
            The connector mod confirms your ownership of the server towards the YoLauncher web services. Once confirmed, you can customize the display of your server for the YoLauncher serverlist.<br />
            <b>It has to be ran on <u>every start</u> to continously verifiy ownership to the server</b>
          </DialogContentText>
          <DialogContentText>
            Extract this zip file in your Life is Feudal: Your Own dedicated server root directory.
          </DialogContentText>
          <DialogContentText>
            If you are using the <Link href="https://lifxmod.com/Docs/server-framework.html" target="_blank" title="LiFx Server Autoloader">LiFx Server Autoloader</Link>, restarting the server would automatically run the confirmation of server ownership.
          </DialogContentText>
          <DialogContentText>
            To manually run confirmation you can type <code>exec("mods/yolauncher/init.cs");</code> in your server console window and/or modify main.cs to execute it on start.
          </DialogContentText>
          <Button onClick={props.onDownload} variant="contained" color="primary" startIcon={<DownloadIcon />}>Download</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
  );
}
