import React from 'react';
import { useSnackbar } from 'notistack';
import useAPI from './useAPI';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function UserProvider({children}) {
  const [user, setUser] = React.useState({login: false, steamid: null, lastError: null});
  const { apiRequest } = useAPI();
  const { enqueueSnackbar } = useSnackbar();

  function update() {
    apiRequest(`/v1/user.php?method=session`).then( data => {
      if( data === null ) return;
      if( data.payload.lastError !== null ) enqueueSnackbar(data.payload.lastError, {variant:'error'});
      setUser(data.payload);
    } );
  }

  function logout() {
    apiRequest(`/v1/user.php?method=logout`).then(update);
  }

  const dispatch = { update, logout };

  React.useEffect(dispatch.update, []);

  return (
    <UserStateContext.Provider value={user}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserContext() {
  return [ React.useContext(UserStateContext), React.useContext(UserDispatchContext) ];
}

export { UserStateContext, UserProvider, useUserContext };

// https://kentcdodds.com/blog/how-to-use-react-context-effectively
