import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ChatIcon from '@material-ui/icons/Chat';
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import ServersIcon from '@material-ui/icons/Storage';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadIcon from '@material-ui/icons/GetApp';

import YoLauncherIcon from './common/YoLauncherIcon';
import { useUserContext } from './UserContext';
import { ReactComponent as SteamIcon } from './common/steam.svg';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    visibility: 'hidden',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function WebSkeleton(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [userState, userDispatch] = useUserContext();
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar, {[classes.appBarShift]: drawerOpen || userState.login,})} >
        <Toolbar className={classes.toolbar}>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, (drawerOpen||userState.login) && classes.hide)}>
            <MenuIcon />
          </IconButton>
          <div>
            <Typography variant="h6" noWrap>
              YoLauncher Web
            </Typography>
          </div>
          <div>
            <Paper component="span" style={{display:"inline-block",backgroundColor:'rgba(0,0,0,0.4)', marginLeft: '1em', padding: '0.25em 0.5em'}}>
              <YoLauncherIcon fontSize="small" color="inherit" style={{margin: '0 5px 0 12px', verticalAlign: 'middle'}} />
              <span>{props.gamesLaunched} Games Launched</span>
              <DownloadIcon fontSize="small" color="inherit" style={{margin: '0 5px 0 12px', verticalAlign: 'middle'}} />
              <span>{props.modsInstalled} Mods Installed</span>
            </Paper>
            </div>
        </Toolbar>
      </AppBar>
      <Drawer className={classes.drawer} variant="persistent" anchor="left" open={drawerOpen || userState.login} classes={{paper: classes.drawerPaper}}>
        <div className={classes.drawerHeader}>
          { ! userState.login && (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          ) }
        </div>
        <Divider />
        <List>
          { userState.login ? (
          <>
            <ListItem button component={Link} to="/myservers">
              <ListItemIcon><ServersIcon /></ListItemIcon>
              <ListItemText primary="My Servers" />
            </ListItem>
            <ListItem button component={Link} to="/" onClick={userDispatch.logout}>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </>
          ) : (
          <ListItem button onClick={props.onLoginClick}>
            <ListItemIcon><SvgIcon component={SteamIcon} viewBox="0 0 1000 1000" /></ListItemIcon>
            <ListItemText primary="Login" />
          </ListItem>
          ) }
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component={Link} to="/documentation">
            <ListItemIcon><HelpIcon /></ListItemIcon>
            <ListItemText primary="Documentation" />
          </ListItem>
          <ListItem button component="a" href="https://discord.gg/EH9b6tqQ4C" target="_blank">
            <ListItemIcon><ChatIcon /></ListItemIcon>
            <ListItemText primary="Discord" />
          </ListItem>
        </List>
      </Drawer>
      <main className={clsx(classes.content, {[classes.contentShift]: drawerOpen || userState.login})}>
        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
}
