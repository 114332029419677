import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

function getStepContent(step) {
  switch (step) {
    case 0:
      return `The server was added to the YoLauncher database.`;
    case 1:
      return 'Please download and run the connector mod to verify this server.';
    case 2:
      return `You can now customize how the server is displayed in YoLauncher.`;
    default:
      return 'Unknown step';
  }
}

export default function VerificationProgressDialog(props) {
  const steps = ['Server Added', 'Ownership Verification', 'Server Verified'];
  const activeStep = props.verified ? 2 : 1;
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Verification Progress</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          { steps.map( (label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
              </StepContent>
            </Step>
          ) ) }
        </Stepper>
        { activeStep === steps.length - 1 && (
          <Paper square elevation={0}>
            <Typography>All steps completed - you&apos;re finished</Typography>
          </Paper>
        ) }
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
}
