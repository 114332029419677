import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

export default function EditServerDialog(props) {
  const [data, setData] = React.useState(props.data);

  function handleInput(event) {
    const key = event.target.name;
    const value = event.target.value;
    setData(prevData => ({...prevData, [key]: value}));
  }

  function handleSwitch(event) {
    const key = event.target.name;
    const value = event.target.checked ? '1' : '0';
    setData(prevData => ({...prevData, [key]: value}));
  }

  function saveChanges() {
    props.onClose();
    props.onSubmit(data);
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Edit Server Details</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField fullWidth name="name" value={data.name} size="small" onChange={handleInput} variant="outlined" label="Server Name" helperText="Server name to identify the server in your server list. This is not public." />
        </Box>
        <Box mb={2}>
          <TextField fullWidth name="description" value={data.description || ''} size="small" onChange={handleInput} variant="outlined" label="Description" helperText="Short description (max 500 chars) of your server. Displayed in the launcher." />
        </Box>
        <Box mb={2}>
          <TextField fullWidth name="ft_id" value={data.ft_id || ''} size="small" onChange={handleInput} variant="outlined" label="FeudalTools Server ID" helperText="Used to import map name/image and mod details to YoLauncher" />
        </Box>
        <Box mb={2}>
          <TextField fullWidth name="website_url" value={data.website_url || ''} size="small" onChange={handleInput} variant="outlined" label="Website Link" />
        </Box>
        <Box mb={2}>
          <TextField fullWidth name="livemap_url" value={data.livemap_url || ''} size="small" onChange={handleInput} variant="outlined" label="Livemap Link" />
        </Box>
        <Box mb={2}>
          <TextField fullWidth name="discord_url" value={data.discord_url || ''} size="small" onChange={handleInput} variant="outlined" label="Discord Link" />
        </Box>
        <Box mb={2}>
          <TextField fullWidth name="teamspeak" value={data.teamspeak || ''} size="small" onChange={handleInput} variant="outlined" label="Teamspeak Address" />
        </Box>
        <Box mb={2}>
          <FormControlLabel control={<Switch name="enable_modpack_selection" onChange={handleSwitch} checked={data.enable_modpack_selection === '1'} />} label="Allow Modpack Version Selection" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">Cancel</Button>
        <Button onClick={saveChanges} color="primary">Save Changes</Button>
      </DialogActions>
    </Dialog>
  );
}
