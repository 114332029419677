import Divider from '@material-ui/core/Divider';

export default function LandingPageSection({children, footer}) {
  return (
    <>
      <section style={{maxWidth: "1200px", margin: "3em auto"}}>
        {children}
      </section>
      { ! footer && <Divider /> }
    </>
  );
}

LandingPageSection.defaultProps = { footer: false };
