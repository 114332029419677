import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function UploadProgressDialog({open, progress}) {
  return (
    <Dialog open={open}>
      <DialogContent>
        <Typography gutterBottom variant="h4">Uploading ...</Typography>
        <LinearProgress variant="determinate" value={progress} style={{marginBottom: '20px'}}/>
      </DialogContent>
    </Dialog>
  );
}
