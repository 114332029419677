// Custom react hook to handle api requests and automate notifications
import React from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
axios.defaults.withCredentials = true;

export default function useAPI() {
  const { enqueueSnackbar } = useSnackbar();

  function apiRequest(path) {
    return axios.get(`${process.env.REACT_APP_API_URL}${path}`).then(handleApiResponse).catch(handleApiError);
  }

  function upload(path, params, file, onUploadProgress = () => {}) {
    const formData = new FormData();
    for( const key in params ) params.hasOwnProperty(key) && formData.append(key, params[key]);
    formData.append('file', file);
    return axios.post(`${process.env.REACT_APP_API_URL}${path}`, formData, {headers: {'Content-Type': 'multipart/form-data'}, onUploadProgress}).then(handleApiResponse).catch(handleApiError);
  }

  function handleApiResponse(response) {
    process.env.NODE_ENV === 'development' && console.log(response);
    if( response.data.result ) {
      response.data.message !== null && enqueueSnackbar(response.data.message, {variant:'success'})
    } else {
      enqueueSnackbar(response.data.message ? response.data.message : 'API request failed', {variant:'error'});
    }
    return response.data;
  }

  function handleApiError() {
    enqueueSnackbar('API request failed', {variant:'error'});
    return null;
  }

  function download(path) {
    window.location.href = `${process.env.REACT_APP_API_URL}${path}`;
  }

  return { apiRequest, download, upload };
}
