import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

export default function DocumentationPage(props) {
  const qaBoxStyle = {paddingTop: "2em"};
  return (
    <Box style={{maxWidth: "1000px", margin: "3em auto"}}>
      <Typography gutterBottom variant="h4">YoLaucher Web Help & Documentation</Typography>
      <Paper style={{padding: "20px", marginBottom: "20px"}}>
        <Typography variant="h5">Server Registration</Typography>
        <Divider />
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>Why Register a Server?</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            The YoLauncher App fetches information about existing servers from different sources, such as Steam and feudal.tools.
            In addition to that, YoLauncher supports a set of extended details defined by the server owner.
            Defining a server desciption, assign themed tags, recommed client mods and even modpack uploads can be done on this website.
          </Typography>
        </Box>
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>Connector Mod</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            In order to let you configure details about your server, we need to confirm your ownership of the server.
            Therefore the server manager generates a custom mini-mod for your server that pings the yolauncher.app website on startup and sends a security code to confirm your ownership.
            Please keep in mind that this mod is generated for your server individually and cannot be used on other servers.
            If you decide to delete and re-add your server on yolauncher.app, you need to redownload a new connector mod instead of using the older download.
          </Typography>
        </Box>
      </Paper>
      <Paper style={{padding: "20px"}}>
        <Typography variant="h5">Server Modpacks</Typography>
        <Divider />
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>About Server-Modpacks</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            Server Modpacks are zip packages containing individual configurations, scripts and assets for a specific server.
            They can be uploaded for each server registered on the YoLauncher Website.
            The YoLauncher App is designed to download and install the modpack when a user attempts to join the respective server.
          </Typography>
        </Box>
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>Multi-Version Support</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            You can host up to 4 different versions of your modpack and choose one (or none) for distribution. The YoLauncher App has an option that allows
            you to manually choose from all available modpack versions prior game launch. This allows you to test and verify new versions before official
            distribution to your players. It also gives you the option to revert to an older modpack version if necessary.
          </Typography>
        </Box>
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>Modpack Destination Path</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            If you include custom 3D assets, textures or audio it is crucial to know the installation path of the modpack within the
            LiF:YO game directory as you will have to reference them in the XML configs. All contents of your uploaded zip package are extracted to ~/yolauncher/modpack/
          </Typography>
        </Box>
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>XML Configuration Files</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            You may include client XML configurations in your modpack. These are detected (by file name) and copied to the ~/data/ folder of the game directory,
            overwriting existing XMLs. It doesn't matter where you put them within the zip package. Just make sure their filename matches the original one from the data folder.
          </Typography>
        </Box>
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>File Type Restrictions</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            For security reasons, we can allow a fixed set of file types that are relevant to the game only.
            These are: cs, gui, xml, dae, dds, dts, xyz, png, jpg, jpeg, ogg, ogv and ttf.
            Modpacks containing other file extensions are rejected.
          </Typography>
        </Box>
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>Scripts Autoloading</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            The YoLauncher App includes the LifX-Framework by default. Any LifX compatible client mods contained in your modpack will autoload, as described <Link href="https://www.lifxmod.com/mod-tutorials/framework/client-autoloader-2-x-x/" target="_blank">here</Link>.
          </Typography>
        </Box>
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>Scripting Restrictions</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            For security reasons, we cannot allow cs-scripts containing code or functions that can be used to cause harm on a players computer.
            This is why all cs files are scanned for potentially malicious code, such as eval, exec, fileDelete, pathCopy, FileObject, or SimDownloadHelper.
            Because we have to scan the cs-files, compiled cs-scripts are currently not allowed.
            If you require to make use of these functions or compiled scripts, we suggest forge a general
            client-mod and have it added to the list of official client mods in YoLauncher. Contact us.
          </Typography>
        </Box>
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>BasilMod::Pack Compatibility</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            It is highly recommended to either make use of BasilMod::Pack or YoLauncher's modpacks to distribute files to users.
            There is no know scenario that would make sence of using both. If you upload and distribute a modpack through YoLauncher,
            the BasilMod::Pack client mod is set to 'forbidden' by default. This is to avoid compability issues for users,
            as BasilMod would potentially revert XML files contained in modpacks. If you have a use-case of utilizing both methods whatsoever,
            you may re-allow BasilMod in the client mod rules configuration of your server.
          </Typography>
        </Box>
        <Box style={qaBoxStyle}>
          <Typography gutterBottom variant="body1">
            <strong>Private/Hidden Servers</strong>
          </Typography>
          <Typography gutterBottom variant="body1">
            Modpacks are fully supported on private (unlisted) servers through the 'Direct Connect' function in YoLauncher.
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}
