import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { UserProvider } from './UserContext';
import WebSkeleton from './WebSkeleton';
import LandingPage from './LandingPage';
import ServerManager from './ServerManager';
import DocumentationPage from './DocumentationPage';
import LoginDialog from './LoginDialog';
import useAPI from './useAPI';

export default function App() {
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [gamesLaunched, setGamesLaunched] = React.useState(0);
  const [modsInstalled, setModsInstalled] = React.useState(0);
  const { apiRequest } = useAPI();

  function getStats() {
    apiRequest(`/v1/stats.php?method=get`).then( ({payload}) => {
      setGamesLaunched(payload.gamesLaunched);
      setModsInstalled(payload.modsInstalled);
    } );
  }

  React.useEffect(getStats, []);

  return (
    <UserProvider>
      <Router>
        <WebSkeleton onLoginClick={() => setLoginOpen(true)} {...{gamesLaunched, modsInstalled}}>
          <Route path="/myservers" component={ServerManager} />
          <Route path="/documentation" component={DocumentationPage} />
          <Route exact path="/" render={props => <LandingPage {...props} onLoginClick={() => setLoginOpen(true)} />} />
          <LoginDialog open={loginOpen} onClose={() => setLoginOpen(false)} />
        </WebSkeleton>
      </Router>
    </UserProvider>
  );
}
