import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import { DropzoneArea } from 'material-ui-dropzone';

export default function ModpackDialog(props) {
  const [file, setFile] = React.useState(null);
//  const [howToExpanded, expandHowTo] = React.useState(false);
  const maxFileSizeMb = 1024;

  const handleFileChange = files => setFile(files.length ? files[0] : null);

  function submit() {
    props.onSubmit(file);
    props.onClose();
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Upload Server Modpack</DialogTitle>
      <DialogContent>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">How to use modpacks ...</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div>Modpacks are synchronized and unpacked into the ~/yolauncher/modpack path of the client directory. You may include your custom xml files, 3d models, textures and client-side scripts to have them distributed to your players.</div>
              <ul>
                <li>Modpacks must be packed in zip format</li>
                <li>Any valid XML files included replace the XML files in the client's data path</li>
                <li>Any LiFX compatible mods and scripts are autoloaded on game launch</li>
              </ul>
              <div>For security reasons, modpacks <u>cannot</u> include:</div>
              <ul>
                <li>Compiled Torquescript (cs.dso files)</li>
                <li>Files with extensions other than cs, gui, xml, dae, dds, dts, xyz, png, jpg, jpeg, ogg, ogv and ttf</li>
                <li>Torquescript that includes potentially harmful commands such as eval, exec, fileDelete, pathCopy, FileObject, or SimDownloadHelper</li>
              </ul>
              <div>You can find more detailed information in this <Link href="https://nyuton.net/yolauncher-0-6-update" target="_blank">blog article</Link></div>
            </div>
          </AccordionDetails>
        </Accordion>
        <DropzoneArea
          filesLimit={1}
          acceptedFiles={['application/*']}
          maxFileSize={maxFileSizeMb*1024*1024}
          dropzoneText={"Drag and drop a zip file here or click"}
          onChange={handleFileChange}
          showAlerts={false}
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{container: { spacing: 1, direction: 'row' }}}
          previewText="Selected File:"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">Cancel</Button>
        <Button onClick={submit} color="primary" disabled={file===null}>Upload</Button>
      </DialogActions>
    </Dialog>
  );
}
