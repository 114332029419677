import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import UsersIcon from '@material-ui/icons/People';
import ServersIcon from '@material-ui/icons/Storage';
import { ReactComponent as SteamIcon } from './common/steam.svg';

export default function LandingPageFeatures(props) {
  return (
    <>
      <Typography gutterBottom variant="h3" align="center">YoLauncher Benefits</Typography>
      <Grid container justify="center" spacing={3}>
        <Grid item md={6}>
          <Paper elevation={0} style={{padding: "2em"}}>
            <Typography variant="h5"><UsersIcon /> For Players</Typography>
            <ul>
    					<li>View serverlist with extended details, such as:</li>
              <ul>
                <li>Map Preview</li>
                <li>Server Region</li>
                <li>Livemap Shortcut</li>
                <li>Server description filed by owner(s)</li>
                <li>Links to server websites, Discord and Teamspeak</li>
                <li>GuildGUI & Trading Post Capability</li>
                <li>Tags for themed servers (RP, PvP, PvE, ...)</li>
              </ul>
    					<li>Useful search & filter functions</li>
    					<li>Homeserver Feature: Quickjoin your favorite server</li>
    					<li>Client Mod Manager: Featuring the most popular client mods</li>
              <li>Automated server modpack download and updates</li>
    				</ul>
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper elevation={0} style={{padding: "2em"}}>
            <Typography variant="h5"><ServersIcon /> For Servers</Typography>
            <ul className='short-feature-list'>
    					<li>Display more details about your server</li>
              <li>Find more players interested in your themes and tags</li>
              <li>Benefit from text search and region filters</li>
              <li>Inform players about your ruleset</li>
              <li>Recommend, enforce or disallow certain client mods</li>
    					<li>Upload & sync individual server modpacks</li>
    				</ul>
            <Button onClick={props.onLoginClick} color="primary" variant="contained" startIcon={<SvgIcon component={SteamIcon} viewBox="0 0 1000 1000" />}>Register Server</Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
